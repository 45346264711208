.mainPhoto {
  padding: 4px;
  border: 1px solid #aaa;
  border-radius: 8px;
  margin-bottom: 16px;
}

.imageGrid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 4px 16px 4px;
}
.imageGridItem {
  overflow: hidden;
  width: 100%;
  padding: 4px;
  border: 1px solid #aaa;
  border-radius: 8px;
}

.imageGridItem a img {
  width: 100%;
}

@media (min-width: 767px) {
  .imageGridItem {
    overflow: hidden;
    width: 128px;
    height: 128px;
    padding: 4px;
    border: 1px solid #aaa;
    border-radius: 8px;
  }

  .imageGridItem a img {
    height: 128px;
    width: auto;
    transform: scale(2);
  }
}
