.show-modal-link {
  cursor: pointer;
}

.itemPhoto {
  height: 192px;
  overflow: hidden;
}

.itemPhoto a img {
  width: 100%;
  height: auto;
}
