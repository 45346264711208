.overlay {
  background-color: #29313277;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  /* pointer-events: none; */

  position: absolute;
  top: 0;
  right: 0;
}
