/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --main-color: #003459;
  --main-color-dark: #002a47;
  --secondary-color: #007ea7;
}

* {
  color: #00171f;
}

body {
  background-image: url("/public/img/home-bg.jpg");
  background-size: cover;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.wrapper {
  background-image: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0.35),
    rgba(0, 0, 0, 0.35)
  );
  min-height: 100vh;
}

.dashboard-content {
  background-color: #ddd;
  min-height: calc(100vh - 56px);
}

html {
  height: -webkit-fill-available;
}

.search-content {
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(100vh - 56px);
  overflow-x: auto;
  overflow-y: hidden;
}

.admin-dashboard-content {
  background-color: #fff;
  min-height: calc(100vh - 48px);
}

.bi {
  vertical-align: -0.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}
.lh-tight {
  line-height: 1.25;
}

.main-sidebar {
  min-height: calc(100vh - 56px);
  z-index: 2;
}

.result-content {
  background-image: linear-gradient(
    to top right,
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)
  );
}
.main-footer {
  bottom: 0;
  right: 0;
  padding: 12px;
  background-color: #fff;
  width: 100%;
  z-index: 1;
}
.main-footer p {
  margin-bottom: 0;
  margin-left: 280px;
}

/****/

.main-content {
  min-height: calc(100vh - (49px));
}

.anchor-pointer {
  cursor: pointer;
}

form:invalid button[type="submit"] {
  opacity: 0.5;
  pointer-events: none;
}

.invalid-input {
  /* border: #8f2606; */
  border: 1px solid #8f2606;
  color: #8f2606;
}

.anchor-button {
  cursor: pointer;
}

.floating-button {
  z-index: 1030;
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
}

.floating-button-show {
  opacity: 0.8;
  pointer-events: all;
}
